
          @use "sass:math";
          @import "~@/assets/scss/abstract.scss";
        































.form-check-input {
  .is-invalid & {
    border-color: $form-feedback-invalid-color;
  }
}

.form-check-label {
  .is-invalid & {
    color: $form-feedback-invalid-color;
  }
}
